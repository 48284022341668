import {
  Button,
  Divider,
  Field,
  Select,
  Subtitle2,
  makeStyles,
  tokens,
  useIntl,
} from '@aisekisan/bond'
import { Fragment, type ReactElement } from 'react'
import type { FieldError, UseFormReturn } from 'react-hook-form'
import { Add20Regular, Dismiss20Regular } from '@fluentui/react-icons'
import type { LocaledEquipmentClass } from '@aisekisan/anya-api'
import type {
  ConstructionAIParamsFormBody,
  ConstructionAIParamsFormRuleBody,
} from './type'
import { ConstructionAIParamsFormFormula } from './formula'
import { T } from '@/libs/intl/t'

const useStyles = makeStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: tokens.spacingHorizontalS,
  },
  add: {
    maxWidth: '100%',
  },
  equip: {
    display: 'flex',
    gap: tokens.spacingHorizontalS,
  },
  field: {
    flex: 1,
  },
})

export function ConstructionAIParamsFormRule(props: {
  form: UseFormReturn<ConstructionAIParamsFormBody>
  equipmentClasses: LocaledEquipmentClass[]
}): ReactElement {
  const { form, equipmentClasses } = props

  const rules = form.watch('rules')

  const styles = useStyles()

  const addRule = () => {
    const next = [
      ...rules,
      {
        id: crypto.randomUUID(),
        equipmentClass: '',
        formulas: [{
          id: crypto.randomUUID(),
          from: '',
          to: '',
          diameter: '',
        }],
      },
    ]
    form.setValue('rules', next)
  }

  const removeRule = (index: number) => {
    if (rules.length === 1)
      throw new Error('Cannot remove the last rule')
    const next = [...rules]
    next.splice(index, 1)
    form.setValue('rules', next)
  }

  return (
    <div>
      <Subtitle2>
        <T id="construction.ai-param.rule.title" />
      </Subtitle2>
      <div className={styles.container}>
        {rules.map((rule, index) => {
          const ruleErrors = form.formState.errors.rules?.[index]
          return (
            <Fragment key={rule.id}>
              <EquipField
                rule={rule}
                rules={rules}
                equipmentClasses={equipmentClasses}
                updateEquip={(equip) => {
                // When equipment class is updated, trigger validation for all rules' equipment class
                  form.setValue(`rules.${index}.equipmentClass`, equip)
                  rules.forEach((_, i) => form.trigger(`rules.${i}.equipmentClass`))
                }}
                removeRule={() => removeRule(index)}
                error={ruleErrors?.equipmentClass}
              />
              <ConstructionAIParamsFormFormula
                form={form}
                ruleIndex={index}
                formulas={rule.formulas}
              />
              {index !== rules.length - 1 && <Divider />}
            </Fragment>
          )
        })}
        <Button
          className={styles.add}
          icon={<Add20Regular />}
          onClick={addRule}
        />
      </div>
    </div>
  )
}

function EquipField(props: {
  rule: ConstructionAIParamsFormRuleBody
  rules: ConstructionAIParamsFormRuleBody[]
  equipmentClasses: LocaledEquipmentClass[]
  updateEquip: (equip: string) => void
  removeRule: () => void
  error?: FieldError
}): ReactElement {
  const { rule, rules, equipmentClasses, updateEquip, removeRule, error } = props

  const { lang } = useIntl()
  const styles = useStyles()

  return (
    <Field
      label={<T id="construction.ai-param.rule.equipment.field" />}
      className={styles.field}
      validationState={error ? 'error' : 'none'}
      validationMessage={error?.message}
    >
      <div className={styles.equip}>
        <Select
          appearance="filled-darker"
          value={rule.equipmentClass}
          onChange={(_, data) => updateEquip(data.value)}
        >
          <option value="" disabled hidden></option>
          {equipmentClasses
            .filter(({ locale }) => locale === lang)
            .map(({ equipmentClass, localed }) => (
              <option key={equipmentClass} value={equipmentClass}>
                {localed}
              </option>
            ))}
        </Select>
        {rules.length > 1 && <Button icon={<Dismiss20Regular />} onClick={removeRule} />}
      </div>
    </Field>
  )
}

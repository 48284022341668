import { Button, Field, Input, makeStyles, mergeClasses, tokens } from '@aisekisan/bond'
import type { ReactElement } from 'react'
import { Add20Regular, Dismiss20Regular } from '@fluentui/react-icons'
import type { UseFormReturn } from 'react-hook-form'
import type {
  OrgAIParamsFormBody,
  OrgAIParamsFormFormulaBody,
} from './type'
import { T } from '@/libs/intl/t'

const useStyles = makeStyles({
  container: {
    display: 'flex',
    columnGap: tokens.spacingHorizontalS,
  },
  inputs: {
    width: '100%',
    display: 'flex',
    columnGap: tokens.spacingHorizontalS,
    alignItems: 'flex-start',
  },
  item: {
    flexGrow: 1,
    width: 0,
    display: 'flex',
    flexDirection: 'column',
    rowGap: tokens.spacingVerticalXXS,
  },
  actions: {
    display: 'flex',
    columnGap: tokens.spacingHorizontalS,
    alignItems: 'end',
  },
  error: {
    marginBottom: tokens.spacingVerticalXL,
  },
})

export function OrgAIParamsFormFormula(props: {
  form: UseFormReturn<OrgAIParamsFormBody>
  ruleIndex: number
  formulas: OrgAIParamsFormFormulaBody[]
}): ReactElement {
  const { form, ruleIndex, formulas } = props

  const updateFormulas = (index: number, formula: OrgAIParamsFormFormulaBody) => {
    form.setValue(
      `rules.${ruleIndex}.formulas.${index}`,
      formula,
      { shouldValidate: true },
    )
  }

  const addFormula = (index: number) => {
    const next = [...formulas]
    next.splice(
      index + 1,
      0,
      { id: crypto.randomUUID(), from: '', to: '', diameter: '' },
    )
    form.setValue(`rules.${ruleIndex}.formulas`, next)
  }

  const removeFormula = (index: number) => {
    if (formulas.length === 1)
      return
    const next = [...formulas]
    next.splice(index, 1)
    form.setValue(`rules.${ruleIndex}.formulas`, next)
  }

  return (
    <>
      {formulas.map((formula, index) => (
        <Formula
          key={formula.id}
          form={form}
          ruleIndex={ruleIndex}
          formulaIndex={index}
          formula={formula}
          onUpdate={next => updateFormulas(index, next)}
          onAdd={() => addFormula(index)}
          onRemove={() => removeFormula(index)}
        />
      ))}
    </>
  )
}

function Formula(props: {
  form: UseFormReturn<OrgAIParamsFormBody>
  ruleIndex: number
  formulaIndex: number
  formula: OrgAIParamsFormFormulaBody
  onUpdate: (formula: OrgAIParamsFormFormulaBody) => void
  onAdd: () => void
  onRemove: () => void
}): ReactElement {
  const { form, ruleIndex, formulaIndex, formula, onUpdate, onAdd, onRemove } = props
  const errors = form.formState.errors.rules?.[ruleIndex]?.formulas?.[formulaIndex]

  const styles = useStyles()

  return (
    <div key={formula.id} className={styles.container}>
      <div className={styles.inputs}>
        <Field
          label={<T id="org.ai-param.min.field" />}
          className={styles.item}
          validationState={errors?.from ? 'error' : 'none'}
          validationMessage={errors?.from?.message}
        >
          <Input
            type="number"
            appearance="filled-darker"
            value={formula.from}
            onChange={(_, data) => onUpdate({ ...formula, from: data.value })}
          />
        </Field>
        <Field
          label={<T id="org.ai-param.max.field" />}
          className={styles.item}
          validationState={errors?.to ? 'error' : 'none'}
          validationMessage={errors?.to?.message}
        >
          <Input
            type="number"
            appearance="filled-darker"
            value={formula.to}
            onChange={(_, data) => onUpdate({ ...formula, to: data.value })}
          />
        </Field>
        <Field
          label={<T id="org.ai-param.diameter.field" />}
          className={styles.item}
          validationState={errors?.diameter ? 'error' : 'none'}
          validationMessage={errors?.diameter?.message}
        >
          <Input
            type="number"
            appearance="filled-darker"
            value={formula.diameter}
            onChange={(_, data) => {
              onUpdate({ ...formula, diameter: data.value })
            }}
          />
        </Field>
      </div>
      <div className={mergeClasses(styles.actions, errors && styles.error)}>
        <Button icon={<Dismiss20Regular />} onClick={onRemove} />
        <Button icon={<Add20Regular />} onClick={onAdd} />
      </div>
    </div>
  )
}
